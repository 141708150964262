import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { compose, withProps } from 'recompose';
import { breakpoint } from '../../se/utilities/responsive';
import {
  analyticsConfigMutation,
  analyticsConfigQuery,
  dashboardQuery,
  sendAnalyticsReport,
} from '../../graph/dashboard';
import Filters from './analytics/Filters';
import GraphLayout from './analytics/v1/GraphLayout';
import TitleAction from '../../se/components/TitleAction';
import withFilterQueryString from '../../se/hocs/withFilterQueryString';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { CenteredSpinner } from '../../se/components/Spinner';
import { RouterlessModal } from '../../se/components/Modal';
import Form from '../../se/components/Form';
import get from 'lodash/get';
import useModal from '../../hooks/useModal';
import { withLabel } from '../../se/components/Label';
import ReportRecipients from './sensor/ReportRecipients';
import ObjectInput from '../../se/components/inputs/ObjectInput';
import Box from '@material-ui/core/Box';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { NamedRange } from '../core/DatePicker';
import { YearMonth } from '@js-joda/core';
import TextInput from '../../se/components/inputs/TextInput';
import NumberInput from '../../se/components/inputs/NumberInput';
import HasPostOpNumberInput from '../../se/components/inputs/HasPostOpNumberInput';
import toArray from 'lodash/toArray';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import GeneralAnalytics from './analytics/GeneralAnalytics';
import ORUtilizationAnalytics from './analytics/ORUtilizationAnalytics';
import PhysicianUtilizationAnalytics from './analytics/PhysicianUtilizationAnalytics';
import HospitalRating from './analytics/HospitalRating';
import PWAInstallBanner from '../../se/components/PWAInstallBanner';
import pick from 'lodash/fp/pick';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import TabNavigation from './sensor/TabNavigation';
import { useScope } from '../../hooks/useScope';
import { withScope } from '../../contexts/ScopeContext';
import PDFAnalyticsButton from './analytics/pdf/PDFAnalyticsButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Aggregation, AggregationContext } from './analytics/Aggregation';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'absolute',
    margin: 'auto',
  },
  actions: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const Stats = withStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr auto',
    },
  },
}))(Box);

export const roundValue = (value: number, digits: number = 1) =>
  value ? (Math.round(value * 100) / 100).toFixed(digits) : 0;

const AnalyticsConfigInputV1 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
  },
})(ObjectInput);

const AnalyticsConfigInputV2 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
    totalPatientsThreshold: compose(
      withLabel('Total Patients Threshold'),
      withProps({ name: 'totalPatientsThreshold' })
    )(NumberInput),
    waitingRoomTimeThreshold: compose(
      withLabel('Waiting Room Time Threshold'),
      withProps({ name: 'waitingRoomTimeThreshold' })
    )(NumberInput),
    preopTimeThreshold: compose(
      withLabel('PreOp Time Threshold'),
      withProps({ name: 'preopTimeThreshold' })
    )(NumberInput),
    readyForORThreshold: compose(
      withLabel('Ready for OR to PreOp Exit Threshold'),
      withProps({ name: 'readyForORThreshold' })
    )(NumberInput),
    orUtilThreshold: compose(
      withLabel('OR Utilization Threshold'),
      withProps({ name: 'orUtilThreshold' })
    )(NumberInput),
    orTimeThreshold: compose(withLabel('OR Time Threshold'), withProps({ name: 'orTimeThreshold' }))(NumberInput),
    cleaningTimeThreshold: compose(
      withLabel('Cleaning Time Threshold'),
      withProps({ name: 'cleaningTimeThreshold' })
    )(NumberInput),
    popiTimeThreshold: compose(
      withLabel('OR Turnover Time Threshold'),
      withProps({ name: 'popiTimeThreshold' })
    )(NumberInput),
    pacuTimeThreshold: compose(withLabel('Pacu Time Threshold'), withProps({ name: 'pacuTimeThreshold' }))(NumberInput),
    postOpTimeThreshold: compose(withScope, withProps({ name: 'postOpTimeThreshold' }))(HasPostOpNumberInput),

    pipoMinThreshold: compose(
      withLabel('Patient In Patient Out Min'),
      withProps({ name: 'pipoMinThreshold' })
    )(NumberInput),
    pipoMaxThreshold: compose(
      withLabel('Patient In Patient Out Max'),
      withProps({ name: 'pipoMaxThreshold' })
    )(NumberInput),
    popiMinThreshold: compose(withLabel('OR Turnover Min'), withProps({ name: 'popiMinThreshold' }))(NumberInput),
    popiMaxThreshold: compose(withLabel('OR Turnover Max'), withProps({ name: 'popiMaxThreshold' }))(NumberInput),
    wrMinThreshold: compose(withLabel('Waiting Room Min'), withProps({ name: 'wrMinThreshold' }))(NumberInput),
    wrMaxThreshold: compose(withLabel('Wairing Room Max'), withProps({ name: 'wrMaxThreshold' }))(NumberInput),
    preOpMinThreshold: compose(withLabel('PreOp Min'), withProps({ name: 'preOpMinThreshold' }))(NumberInput),
    preOpMaxThreshold: compose(withLabel('PreOp Max'), withProps({ name: 'preOpMaxThreshold' }))(NumberInput),
    cleaningMinThreshold: compose(
      withLabel('Cleaning Time Min'),
      withProps({ name: 'cleaningMinThreshold' })
    )(NumberInput),
    cleaningMaxThreshold: compose(
      withLabel('Cleaning Time Max'),
      withProps({ name: 'cleaningMaxThreshold' })
    )(NumberInput),
    pacuMinThreshold: compose(withLabel('Pacu Min'), withProps({ name: 'pacuMinThreshold' }))(NumberInput),
    pacuMaxThreshold: compose(withLabel('Pacu Max'), withProps({ name: 'pacuMaxThreshold' }))(NumberInput),
    postOpMinThreshold: compose(withLabel('PostOp Min'), withProps({ name: 'postOpMinThreshold' }))(NumberInput),
    postOpMaxThreshold: compose(withLabel('PostOp Max'), withProps({ name: 'postOpMaxThreshold' }))(NumberInput),
    preOpToDischargedMinThreshold: compose(
      withLabel('PreOp to Discharge Min'),
      withProps({ name: 'preOpToDischargedMinThreshold' })
    )(NumberInput),
    preOpToDischargedMaxThreshold: compose(
      withLabel('PreOp to Discharge Max'),
      withProps({ name: 'preOpToDischargedMaxThreshold' })
    )(NumberInput),
    inOrMinThreshold: compose(withLabel('OR Min'), withProps({ name: 'inOrMinThreshold' }))(NumberInput),
    inOrMaxThreshold: compose(withLabel('OR Max'), withProps({ name: 'inOrMaxThreshold' }))(NumberInput),
    inSurgeryMinThreshold: compose(
      withLabel('In Surgery Min'),
      withProps({ name: 'inSurgeryMinThreshold' })
    )(NumberInput),
    inSurgeryMaxThreshold: compose(
      withLabel('In Surgery Max'),
      withProps({ name: 'inSurgeryMaxThreshold' })
    )(NumberInput),
    closingMinThreshold: compose(withLabel('Closing Min'), withProps({ name: 'closingMinThreshold' }))(NumberInput),
    closingMaxThreshold: compose(withLabel('Closing Max'), withProps({ name: 'closingMaxThreshold' }))(NumberInput),
    procedureCompleteMinThreshold: compose(
      withLabel('Post Closing Min'),
      withProps({ name: 'procedureCompleteMinThreshold' })
    )(NumberInput),
    procedureCompleteMaxThreshold: compose(
      withLabel('Post Closing Max'),
      withProps({ name: 'procedureCompleteMaxThreshold' })
    )(NumberInput),
    orReadyMinThreshold: compose(withLabel('OR Ready Min'), withProps({ name: 'orReadyMinThreshold' }))(NumberInput),
    orReadyMaxThreshold: compose(withLabel('OR Ready Max'), withProps({ name: 'orReadyMaxThreshold' }))(NumberInput),
    piToStartMinThreshold: compose(
      withLabel('Patient In to Start Min'),
      withProps({ name: 'piToStartMinThreshold' })
    )(NumberInput),
    piToStartMaxThreshold: compose(
      withLabel('Patient In to Start Max'),
      withProps({ name: 'piToStartMaxThreshold' })
    )(NumberInput),
    poToCutMinThreshold: compose(
      withLabel('Patient Out to Cut Min'),
      withProps({ name: 'poToCutMinThreshold' })
    )(NumberInput),
    poToCutMaxThreshold: compose(
      withLabel('Patient Out to Cut Max'),
      withProps({ name: 'poToCutMaxThreshold' })
    )(NumberInput),
    startToCloseMinThreshold: compose(
      withLabel('Start to Close Min'),
      withProps({ name: 'startToCloseMinThreshold' })
    )(NumberInput),
    startToCloseMaxThreshold: compose(
      withLabel('Start to Close Max'),
      withProps({ name: 'startToCloseMaxThreshold' })
    )(NumberInput),
    physicianPopiMinThreshold: compose(
      withLabel('Physician Turnover Min'),
      withProps({ name: 'physicianPopiMinThreshold' })
    )(NumberInput),
    physicianPopiMaxThreshold: compose(
      withLabel('Physician Turnover Max'),
      withProps({ name: 'physicianPopiMaxThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

const DetailedReportButton: FC = () => {
  const { open, openModal, closeModal } = useModal();
  const [mutate] = useMutation(analyticsConfigMutation);
  const { data, loading } = useQuery(analyticsConfigQuery);
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  const handleSubmit = async values => {
    const monthlyReportRecipients = toArray(get(values, 'monthlyReportRecipients'));
    const weeklyReportRecipients = toArray(get(values, 'weeklyReportRecipients'));

    const totalPatientsThreshold = get(values, 'totalPatientsThreshold');
    const waitingRoomTimeThreshold = get(values, 'waitingRoomTimeThreshold');
    const preopTimeThreshold = get(values, 'preopTimeThreshold');
    const readyForORThreshold = get(values, 'readyForORThreshold');
    const orUtilThreshold = get(values, 'orUtilThreshold');
    const orTimeThreshold = get(values, 'orTimeThreshold');
    const pacuTimeThreshold = get(values, 'pacuTimeThreshold');
    const cleaningTimeThreshold = get(values, 'cleaningTimeThreshold');
    const popiTimeThreshold = get(values, 'popiTimeThreshold');
    const postOpTimeThreshold = get(values, 'postOpTimeThreshold');
    const pipoMinThreshold = get(values, 'pipoMinThreshold');
    const pipoMaxThreshold = get(values, 'pipoMaxThreshold');
    const popiMinThreshold = get(values, 'popiMinThreshold');
    const popiMaxThreshold = get(values, 'popiMaxThreshold');
    const wrMinThreshold = get(values, 'wrMinThreshold');
    const wrMaxThreshold = get(values, 'wrMaxThreshold');
    const preOpMinThreshold = get(values, 'preOpMinThreshold');
    const preOpMaxThreshold = get(values, 'preOpMaxThreshold');
    const cleaningMinThreshold = get(values, 'cleaningMinThreshold');
    const cleaningMaxThreshold = get(values, 'cleaningMaxThreshold');
    const pacuMinThreshold = get(values, 'pacuMinThreshold');
    const pacuMaxThreshold = get(values, 'pacuMaxThreshold');
    const postOpMinThreshold = get(values, 'postOpMinThreshold');
    const postOpMaxThreshold = get(values, 'postOpMaxThreshold');
    const preOpToDischargedMinThreshold = get(values, 'preOpToDischargedMinThreshold');
    const preOpToDischargedMaxThreshold = get(values, 'preOpToDischargedMaxThreshold');
    const inOrMinThreshold = get(values, 'inOrMinThreshold');
    const inOrMaxThreshold = get(values, 'inOrMaxThreshold');
    const inSurgeryMinThreshold = get(values, 'inSurgeryMinThreshold');
    const inSurgeryMaxThreshold = get(values, 'inSurgeryMaxThreshold');
    const closingMinThreshold = get(values, 'closingMinThreshold');
    const closingMaxThreshold = get(values, 'closingMaxThreshold');
    const procedureCompleteMinThreshold = get(values, 'procedureCompleteMinThreshold');
    const procedureCompleteMaxThreshold = get(values, 'procedureCompleteMaxThreshold');
    const orReadyMinThreshold = get(values, 'orReadyMinThreshold');
    const orReadyMaxThreshold = get(values, 'orReadyMaxThreshold');
    const piToStartMinThreshold = get(values, 'piToStartMinThreshold');
    const piToStartMaxThreshold = get(values, 'piToStartMaxThreshold');
    const poToCutMinThreshold = get(values, 'poToCutMinThreshold');
    const poToCutMaxThreshold = get(values, 'poToCutMaxThreshold');
    const startToCloseMinThreshold = get(values, 'startToCloseMinThreshold');
    const startToCloseMaxThreshold = get(values, 'startToCloseMaxThreshold');
    const physicianPopiMinThreshold = get(values, 'physicianPopiMinThreshold');
    const physicianPopiMaxThreshold = get(values, 'physicianPopiMaxThreshold');

    const result = await mutate({
      variables: {
        monthlyReportRecipients,
        weeklyReportRecipients,
        totalPatientsThreshold,
        waitingRoomTimeThreshold,
        preopTimeThreshold,
        readyForORThreshold,
        orUtilThreshold,
        orTimeThreshold,
        pacuTimeThreshold,
        cleaningTimeThreshold,
        popiTimeThreshold,
        postOpTimeThreshold,
        pipoMinThreshold,
        pipoMaxThreshold,
        popiMinThreshold,
        popiMaxThreshold,
        wrMinThreshold,
        wrMaxThreshold,
        preOpMinThreshold,
        preOpMaxThreshold,
        cleaningMinThreshold,
        cleaningMaxThreshold,
        pacuMinThreshold,
        pacuMaxThreshold,
        postOpMinThreshold,
        postOpMaxThreshold,
        preOpToDischargedMinThreshold,
        preOpToDischargedMaxThreshold,
        inOrMinThreshold,
        inOrMaxThreshold,
        inSurgeryMinThreshold,
        inSurgeryMaxThreshold,
        closingMinThreshold,
        closingMaxThreshold,
        procedureCompleteMinThreshold,
        procedureCompleteMaxThreshold,
        orReadyMinThreshold,
        orReadyMaxThreshold,
        piToStartMinThreshold,
        piToStartMaxThreshold,
        poToCutMinThreshold,
        poToCutMaxThreshold,
        startToCloseMinThreshold,
        startToCloseMaxThreshold,
        physicianPopiMinThreshold,
        physicianPopiMaxThreshold,
      },
      refetchQueries: [{ query: analyticsConfigQuery }],
    });

    if (get(result, 'data.updateAnalyticsConfiguration')) {
      closeModal();
    }
  };
  return (
    <div>
      <IconButton onClick={openModal}>
        <SettingsIcon />
      </IconButton>
      <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogTitle>Analytics Global Configuration</DialogTitle>
        {!loading ? (
          <DialogContent>
            <Form
              autoFocus
              initialValue={get(data, 'analyticsConfiguration')}
              input={analyticsV2 ? AnalyticsConfigInputV2 : AnalyticsConfigInputV1}
              label={'Save'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </DialogContent>
        ) : (
          <CenteredSpinner />
        )}
      </Dialog>
    </div>
  );
};

const MonthYearInput = withProps({
  schema: {
    monthYear: withLabel('Enter month')(TextInput),
  },
})(ObjectInput);

const Title: FC<{ filter: any }> = ({ filter }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [reportQueryWorking, setReportQueryWorking] = React.useState<boolean>(false);
  const [lastDetailedReport, setLastDetailedReport] = React.useState<any>(null);
  const { open, openModal, closeModal } = useModal();
  const [sendReport] = useMutation(sendAnalyticsReport);

  useEffect(() => {
    if (lastDetailedReport !== null) {
      const timeout = setTimeout(() => {
        setLastDetailedReport(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [lastDetailedReport]);

  const handleSubmit = async values => {
    await sendReport({
      variables: {
        monthYear: values.monthYear,
      },
    });
    closeModal();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography component="h1" variant="h1">
        Analytics
      </Typography>
      <Box display="flex">
        <PDFAnalyticsButton filter={filter} />
        <DetailedReportButton />
        {open && (
          <RouterlessModal
            title={'Send Analytics Report'}
            onClose={closeModal}
            fullWindow={window.innerWidth < breakpoint.md}
          >
            <Form
              autoFocus
              initialValue={{ monthYear: YearMonth.now().toString() }}
              input={MonthYearInput}
              label={'Send'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </RouterlessModal>
        )}
      </Box>
    </Box>
  );
};

export const AnalyticsLayout: FC<{ loading: boolean; children: React.ReactNode }> = ({ loading, children }) =>
  !loading ? (
    <Box mt={2}>
      <Stats>{children}</Stats>
    </Box>
  ) : (
    <CenteredSpinner style={{ height: '30em' }} />
  );

const pickFilter = pick(['physician', 'procedureType', 'dateRange']);

const AnalyticsV1: FC<{ filter: any; setFilter: any; isBusinessManager: boolean }> = ({
  filter,
  setFilter,
  isBusinessManager,
}) => {
  const { dateRange, ...rest } = filter || {};
  // @ts-ignore

  const analyticsConfig = useQuery(analyticsConfigQuery);

  const popiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMinThreshold;
  const popiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMaxThreshold;
  const wrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMinThreshold;
  const wrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMaxThreshold;
  const preOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMinThreshold;
  const preOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMaxThreshold;
  const cleaningMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMinThreshold;
  const cleaningMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMaxThreshold;
  const pacuMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMinThreshold;
  const pacuMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMaxThreshold;
  const postOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMinThreshold;
  const postOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMaxThreshold;
  const inOrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMinThreshold;
  const inOrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMaxThreshold;

  const { data, loading } = useQuery(dashboardQuery, {
    variables: {
      filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) },
      wrMin: wrMinThreshold,
      wrMax: wrMaxThreshold,
      preopMin: preOpMinThreshold,
      preopMax: preOpMaxThreshold,
      orMin: inOrMinThreshold,
      orMax: inOrMaxThreshold,
      cleaningMin: cleaningMinThreshold,
      cleaningMax: cleaningMaxThreshold,
      popiMin: popiMinThreshold,
      popiMax: popiMaxThreshold,
      pacuMin: pacuMinThreshold,
      pacuMax: pacuMaxThreshold,
      postopMin: postOpMinThreshold,
      postopMax: postOpMaxThreshold,
    },
    fetchPolicy: 'network-only',
  });
  const statistics = data?.dashboard || [];

  return (
    <Fragment>
      <TitleAction
        Title={() => <Title filter={filter} />}
        actionStyle={{
          style: { gridTemplateColumns: '1fr', alignItems: 'start' },
        }}
      >
        <Filters onChange={setFilter} value={filter} />
      </TitleAction>

      <PWAInstallBanner />

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        {statistics.map((stat, i) => (
          <GraphLayout key={`${stat.id}-${i}`} {...stat} />
        ))}
      </AnalyticsLayout>
    </Fragment>
  );
};

const tabs = match => [
  {
    id: 'general',
    name: 'General',
    pathname: match.url,
  },
  {
    id: 'operating-room',
    name: 'Analysis by OR',
    pathname: `${match.url}/operating-room`,
  },
  {
    id: 'physician',
    name: 'Analysis by Physician',
    pathname: `${match.url}/physician`,
  },
];

interface AggregationPickerProps {
  value: Aggregation;
  onChange: (value: Aggregation) => void;
}

const AggregationPicker = ({ value, onChange }: AggregationPickerProps) => {
  const handleAggregationChange = (_: React.MouseEvent<HTMLElement>, value: any) => {
    const parsed = Aggregation.safeParse(value);
    onChange(parsed.success ? parsed.data : 'average');
  };

  return (
    <Box display="flex" alignItems="center">
      <ToggleButtonGroup size="small" value={value} exclusive onChange={handleAggregationChange}>
        <ToggleButton value="average">Average</ToggleButton>
        <ToggleButton value="median">Median</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const AnalyticsV2: FC<{ filter: any; setFilter: any; isBusinessManager: boolean } & RouteComponentProps> = ({
  filter,
  setFilter,
  isBusinessManager,
  location,
  match,
}) => {
  const classes = useStyles();
  const shouldHideInputs = location.pathname.includes(`/physician`);

  const initialAggregation = useMemo(() => {
    const aggregation = localStorage.getItem('analytics-aggregation');
    const parsed = Aggregation.safeParse(aggregation);
    return parsed.success ? parsed.data : 'average';
  }, []);

  const [aggregation, setAggregation] = useState<Aggregation>(initialAggregation);

  const handleAggregationChange = (value: Aggregation) => {
    setAggregation(value);
    localStorage.setItem('analytics-aggregation', value);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Title filter={filter} />
        <Box mt={1} mb={3}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between" className={classes.actions}>
          <TabNavigation tabs={tabs(match)} location={location} />
          <Switch>
            <Route
              path={`${match.path}/operating-room`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
            <Route
              path={`${match.path}/physician`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
          </Switch>
          <Filters
            onChange={setFilter}
            value={filter}
            hideProcedureTypeSelectInput={shouldHideInputs}
            hidePhysicianSelectInput={shouldHideInputs}
            reload={false}
          />
        </Box>
      </Box>
      <AggregationContext.Provider value={aggregation}>
        <Switch>
          <Route
            path={`${match.path}/operating-room`}
            render={() => <ORUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`${match.path}/physician`}
            render={() => <PhysicianUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`*`}
            render={() => (
              <GeneralAnalytics filter={filter} setFilter={setFilter} isBusinessManager={isBusinessManager} />
            )}
          />
        </Switch>
      </AggregationContext.Provider>
    </>
  );
};

const Analytics: FC<
  {
    filter: any;
    setFilter: any;
    isBusinessManager: boolean;
  } & RouteComponentProps
> = ({ filter, setFilter, isBusinessManager, location, match }) => {
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  if (analyticsV2) {
    return (
      <AnalyticsV2
        location={location}
        match={match}
        filter={filter}
        setFilter={setFilter}
        isBusinessManager={isBusinessManager}
      />
    );
  }

  return <AnalyticsV1 filter={filter} setFilter={setFilter} isBusinessManager={isBusinessManager} />;
};

export default compose(
  withSession(unpackSessionObject),
  withFilterQueryString({
    dateRange: NamedRange.lastSevenDays(),
  })
  // @ts-ignore
)(Analytics);
