import React from 'react';
import Header from './components/Header';
import MedicalPassportField from '../components/MedicalPassportField';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import PatientInfo from './components/PatientInfo';
import Footer from './components/Footer';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import MedicationTextArea from '../components/MedicationTextArea';
import toHighlightsString3 from '../../../../../questionnaire/toHighlightsString3';

function yesOrNo(value) {
  if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  }
  return '';
}

function yesOrNo2(value, value1) {
  if (value === true) {
    return 'yes';
  } else if (value1 === false) {
    return 'no';
  }
  return '';
}

function yesOrNo3(value) {
  if (value === null || value === '') {
    return '';
  } else if (value.length === 0) {
    return 'no';
  }
  return 'yes';
}

function yesOrNo4(value) {
  if (value === null || value === '') {
    return '';
  } else if (value.none === true) {
    return 'no';
  }else if (value.length === 0) {
    return 'no';
  }
  return 'yes';
}

const Page9 = () => {
  const { questionnaireAnswers } = useValue();
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  )
    ? 'Patient:' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
      ''
    )
    : '';

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  )
    ? 'Family:' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
      ''
    )
    : '';

  let anesthesiaProblem = patientProblem + '\t' + familyProblem;
  // medication
  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );
  let nameArr = [medicationList.length];
  let doseArr = [medicationList.length];
  let routeArr = [medicationList.length];
  let freqArr = [medicationList.length];
  let result = [medicationList.length];
  let purposeArr = [medicationList.length];
  let last = [medicationList.length];
  let status = [medicationList.length];

  for (let i = 0; i < medicationList.length; i++) {
    nameArr[i] = get(medicationList[i], 'schema.name.text', '');
    doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
    routeArr[i] = get(medicationList[i], 'schema.route.option', '');
    freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
    result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
    last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

    purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
    status[i] = get(medicationList[i], 'schema.status.option');
  }
  // snapshot
  const anginaYes = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.angina.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.angina.yes', '')
  );
  const smoker =
    get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '') === 'N'
      ? get(questionnaireAnswers, 'sections.social.schema.smoking.option', '')
      : get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '');
  const snore = yesOrNo(get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q1.yes', ''));
  const heartAttack = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.HeartAttack.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.HeartAttack.yes', '')
  );
  const alc = yesOrNo2(
    get(questionnaireAnswers, 'sections.social.schema.drinking.yes[0]', ''),
    get(questionnaireAnswers, 'sections.social.schema.drinking.yes', '')
  );
  const labWork = yesOrNo(get(questionnaireAnswers, 'sections.PreVisitRequirements.schema.labworkDone.yes', ''));
  const internalNerveSimulator = yesOrNo(
    get(questionnaireAnswers, 'sections.Cardiac.schema.InternalNerveStimulator.yes', '')
  );
  const pacemaker = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.Pacemaker.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.Pacemaker.yes', '')
  );
  const contactLenses = yesOrNo(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.contactLenses.yes', '')
  );
  const hearingAid = yesOrNo(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.hearingproblem.yes', '')
  );
  const diabetes = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.endocrine.schema.endocrine_q1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.endocrine.schema.endocrine_q1.yes', '')
  );
  const cad = yesOrNo(get(questionnaireAnswers, 'sections.Cardiac.schema.CAD.yes', ''));
  const specialAssist = yesOrNo(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.specialAssistance.yes', '')
  );
  const covid19 = yesOrNo2(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.contactwith1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.contactwith1.yes', '')
  );
  const hiv = yesOrNo(get(questionnaireAnswers, 'sections.medicalConditions.schema.liver.schema.liver_q1.yes', ''));
  const envAll = yesOrNo2(
    get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', ''),
    get(questionnaireAnswers, 'sections.allergies.schema.q3.yes', '')
  );
  const sleepApnea = yesOrNo2(
    get(questionnaireAnswers, 'sections.Pulmonary.schema.SleepApnea.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Pulmonary.schema.SleepApnea.yes', '')
  );
  const ekg = yesOrNo(get(questionnaireAnswers, 'sections.PreVisitRequirements.schema.scheduledEKG.yes', ''));
  const hepatitis = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.liver.schema.liver_q3.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.liver.schema.liver_q3.yes', '')
  );
  const kidneyStones = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.kidneyUrinary.schema.kidneyUrinary_q3.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.kidneyUrinary.schema.kidneyUrinary_q3.yes', '')
  );
  const ulcers = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.gastrointestinal.schema.gastrointestinal_q2.yes', '')
  );
  const prescriptions = yesOrNo2(
    get(questionnaireAnswers, 'sections.medications.schema.medicationList.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medications.schema.medicationList.yes', '')
  );
  const cold = yesOrNo2(
    get(questionnaireAnswers, 'sections.Pulmonary.schema.symptoms.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Pulmonary.schema.symptoms.yes', '')
  );
  const highBlood = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.HighBloodPressure.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.HighBloodPressure.yes', '')
  );
  const chronicLBP = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q2.yes', '')
  );
  const myopathy = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q4.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q4.yes', '')
  );
  const fall6 = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q6.yes', '')
  );
  const joint = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q8.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q8.yes', '')
  );
  const otcMed = yesOrNo(get(questionnaireAnswers, 'sections.medications.schema.NSAIDS.yes', ''));
  const faint = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q2.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q2.yes', '')
  );
  const othercondition = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.otherCondition.schema.otherCondition_q3.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.otherCondition.schema.otherCondition_q3.yes', '')
  );
  const tuber = yesOrNo(get(questionnaireAnswers, 'sections.Pulmonary.schema.Tuberculosis.yes', ''));
  const prevSurgery = yesOrNo2(
    get(questionnaireAnswers, 'sections.priorSurgeries.schema.q1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.priorSurgeries.schema.q1.yes', '')
  );
  const pneumonia = yesOrNo(get(questionnaireAnswers, 'sections.Pulmonary.schema.Pneumonia.yes', ''));
  const catheterization = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacCatheterization.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacCatheterization.yes', '')
  );
  const prevAnesth = yesOrNo2(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.associatedProblem.yes[0]', ''),
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.associatedProblem.yes', '')
  );
  const migraines = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q6.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q6.yes', '')
  );
  const stents = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.Stents.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.Stents.yes', '')
  );
  const hasallergies = yesOrNo2(
    get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.allergies.schema.q1.yes', '')
  );
  const asthma = yesOrNo2(
    get(questionnaireAnswers, 'sections.Pulmonary.schema.Asthma.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Pulmonary.schema.Asthma.yes', '')
  );
  const derm = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.dermatology.schema.dermatology_q1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.dermatology.schema.dermatology_q1.yes', '')
  );
  const latexAll = yesOrNo2(
    get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', ''),
    get(questionnaireAnswers, 'sections.allergies.schema.q2.yes', '')
  );
  const emphysema = yesOrNo(get(questionnaireAnswers, 'sections.Pulmonary.schema.COPD.yes', ''));
  const bodypiercing = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.dermatology.schema.dermatology_q2.yes', '')
  );
  const motion = yesOrNo(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema["Motion sickness"].yes', '')
  );
  const looseteeth = yesOrNo4(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.teethProblem.checkboxes', '')
  );
  console.log(get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.teethProblem.checkboxes', ''))
  const eye = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.EyesEarsNoseThroat.schema.EyesEarsNoseThroat_q1.yes[0]',
      ''
    ),
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.EyesEarsNoseThroat.schema.EyesEarsNoseThroat_q1.yes',
      ''
    )
  );
  const aicd = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.defibrillator.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.defibrillator.yes', '')
  );
  const cholesterol = yesOrNo(get(questionnaireAnswers, 'sections.Cardiac.schema.HighCholesterol.yes', ''));
  const assist = yesOrNo2(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.assistiveDevice.yes[0]', ''),
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.assistiveDevice.yes', '')
  );
  const thyroid = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.endocrine.schema.endocrine_q2.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.endocrine.schema.endocrine_q2.yes', '')
  );
  const vascular = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.VascularDisease.yes', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.VascularDisease.yes', '')
  );
  const heartValve = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.HeartValve.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.HeartValve.yes', '')
  );
  const liverDisease = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.liver.schema.liver_q2.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.liver.schema.liver_q2.yes', '')
  );
  const hypertension = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.HighBloodPressure.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.HighBloodPressure.yes', '')
  );
  const recDrugs = yesOrNo2(
    get(questionnaireAnswers, 'sections.social.schema.drugs.yes[0]', ''),
    get(questionnaireAnswers, 'sections.social.schema.drugs.yes', '')
  );
  const stopBreathing = yesOrNo(get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q3.yes', ''));
  const incontinece = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.kidneyUrinary.schema.kidneyUrinary_q1.yes', '')
  );
  const heartburn = yesOrNo(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.gastrointestinal.schema.gastrointestinal_q1.yes', '')
  );
  const digestive = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.gastrointestinal.schema.gastrointestinal_q3.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.gastrointestinal.schema.gastrointestinal_q3.yes', '')
  );
  const lowBlood = yesOrNo(get(questionnaireAnswers, 'sections.Cardiac.schema.LowBloodPressure.yes', ''));
  const xray = yesOrNo(get(questionnaireAnswers, 'sections.PreVisitRequirements.schema.Xray.yes', ''));
  const hasmusc = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q1.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q1.yes', '')
  );
  const arthritis = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q3.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q3.yes', '')
  );
  const paralysis = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q5.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q5.yes', '')
  );
  const metalimplants = yesOrNo2(
    get(
      questionnaireAnswers,
      'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q7.yes[0]',
      ''
    ),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.muscularSkeletal.schema.muscularSkeletal_q7.yes', '')
  );
  const cancer = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.otherCondition.schema.otherCondition_q1.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.otherCondition.schema.otherCondition_q1.yes', '')
  );
  const oxygen = yesOrNo(get(questionnaireAnswers, 'sections.Pulmonary.schema.oxygen.yes', ''));
  const preopphysical = yesOrNo(
    get(questionnaireAnswers, 'sections.PreVisitRequirements.schema.preOpPhysical.yes', '')
  );
  const stressTest = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacStressTest.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacStressTest.yes', '')
  );
  const psychiatricAssess = yesOrNo2(
    get(questionnaireAnswers, 'sections.social.schema.mentalHealth.yes[0]', ''),
    get(questionnaireAnswers, 'sections.social.schema.mentalHealth.yes', '')
  );
  const echo = yesOrNo2(
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacEcho.yes[0]', ''),
    get(questionnaireAnswers, 'sections.Cardiac.schema.cardiacEcho.yes', '')
  );
  const steroids = yesOrNo(get(questionnaireAnswers, 'sections.medications.schema.steroids.yes', ''));
  const stroke = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q5.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q5.yes', '')
  );
  const chf = yesOrNo(get(questionnaireAnswers, 'sections.Cardiac.schema.CongestiveHeartFailure.yes', ''));
  const problemAirway = yesOrNo2(
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.airwayProblem.yes[0]', ''),
    get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.airwayProblem.yes', '')
  );
  const seizures = yesOrNo2(
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q7.yes[0]', ''),
    get(questionnaireAnswers, 'sections.medicalConditions.schema.neurological.schema.neurological_q7.yes', '')
  );

  return (
    <div id="page9" className="page" style={{fontFamily:'Arial', fontSize:'11px'}}>
      <Header pageNumber={9} pageCount={12} />
      <div style={{ textAlign: 'center' }}>
        <h2>HISTORY & PHYSICAL</h2>
      </div>
      <PatientInfo />
      <br></br>
      <div style={{ display: 'grid', gridTemplateColumns: '26% 74%' }}>
        Patient/Family/Complications:
        <MedicationTextArea name="page9.complications" value={anesthesiaProblem} reducer={toHighlightsString3} />
      </div>
      <MedicalPassportField label="Preoperative Diagnosis" name="page9.preopDiagnosis" />
      <br></br>
      <div style={styles.greyBackground}>PATIENT SNAPSHOT</div>
      <div style={styles.twoEqualColumns3}>
        <div style={{ borderRight: '1px solid black' }}>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', paddingLeft:'3px' }}>Gender</div>
            <div>
              <MedicalPassportField
                label=""
                name="page9.genderSnap"
                path={['demographicInformation', 'sex']}
                reducer={toHighlightsString}
              />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Angina</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.anginaSnap" value={anginaYes} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Smoking</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.smokerSnap" value={smoker} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Snore</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.snoreSnap" value={snore} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>MI</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.heartAttackSnap" value={heartAttack} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Alcohol</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.alcSnap" value={alc} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Lab work done</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.labSnap" value={labWork} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Internal nerve simulator</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.nerveSnap" value={internalNerveSimulator} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Pacemaker</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.paceSnap" value={pacemaker} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Contact Lenses</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.contactSnap" value={contactLenses} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Hearing impairment/aid</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.hearingaidSnap" value={hearingAid} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Diabetes</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.diabetesSnap" value={diabetes} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>CAD</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.cadSnap" value={cad} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Special assistance</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.specialAssistSnap" value={specialAssist} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>COVID-19 exposure</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.covidSnap" value={covid19} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>HIV/AIDS</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.hivSnap" value={hiv} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Env. allergies</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.envSnap" value={envAll} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Sleep Apnea</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.sleepSnap" value={sleepApnea} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Pre-Op EKG</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.ekgSnap" value={ekg} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Hepatitis</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.hepatitisSnap" value={hepatitis} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Kidney stones</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.kidneyStonesSnap" value={kidneyStones} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Ulcers</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.ulcerSnap" value={ulcers} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Prescriptions</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.prescriptionSnap" value={prescriptions} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Cold/flu/illness symptoms
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.coldSnap" value={cold} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>High blood pressure</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.highBloodSnap" value={highBlood} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Chronic LBP</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.lbpSnap" value={chronicLBP} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Myopathy</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.myopathySnap" value={myopathy} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Hx of falls in last 6 months
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.fall6Snap" value={fall6} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Joint replacement</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.jointSnap" value={joint} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>OTC Medications</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.otcSnap" value={otcMed} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Fainting</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.faintSnap" value={faint} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Other Medical Conditions</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.otherConditionSnap" value={othercondition} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Tuberculosis</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.tuberSnap" value={tuber} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Previous Surgeries</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.prevSurgerySnap" value={prevSurgery} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Pneumonia</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.pneumoniaSnap" value={pneumonia} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Cardiac Catheterization</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.catheteriationSnap" value={catheterization} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Previous problems with anesthesia
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.prevAnesthSnap" value={prevAnesth} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Migraines</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.migrainesSnap" value={migraines} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div
              style={{ borderRight: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black' }}
            >
              Heart stents
            </div>
            <div style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.stentsSnap" value={stents} />
            </div>
          </div>
        </div>

        <div style={{ borderRight: '1px solid black' }}>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', paddingLeft:'3px' }}>Has allergies</div>
            <div>
              <MedicalPassportField label="" name="page9.hasAllergiesSnap" value={hasallergies} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Asthma</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.asthmaSnap" value={asthma} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Dermatology Assessment</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.dermSnap" value={derm} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Latex allergy</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.latexAllSnap" value={latexAll} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Emphysema or COPD</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.emphysemaSnap" value={emphysema} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Body piercings</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.bodypiercingSnap" value={bodypiercing} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Do you get motion sickness when riding in a car, boat, or plane?
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.motionSnap" value={motion} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Loose teeth, broken teeth, chipped teeth, dentures, partials, crowns, caps or braces
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.teethSnap" value={looseteeth} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Eye conditions</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.eyeSnap" value={eye} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>AICD</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.aicdSnap" value={aicd} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>High cholesterol</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.cholesterolSnap" value={cholesterol} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Assistive Devices</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.assistiveDevicesSnap" value={assist} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Thyroid disease</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.thyroidSnap" value={thyroid} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Vascular disease</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.vascularSnap" value={vascular} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Heart valve problems</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.heartValveSnap" value={heartValve} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Liver disease</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.liverDiseaseSnap" value={liverDisease} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Hypertension</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.hyperTensionSnap" value={hypertension} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Recreational Drugs</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.recDrugsSnap" value={recDrugs} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Stop breathing</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.stopBreathingSnap" value={stopBreathing} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Incontinence</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.incontinenceSnap" value={incontinece} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Heartburn</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.heartburnSnap" value={heartburn} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Digestive Problems</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.digestiveSnap" value={digestive} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Low Blood Pressure</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.lowBloodSnap" value={lowBlood} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Chest X-ray?</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.xraySnap" value={xray} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Has muscular / skeletal coniditions / uses assistive devices
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.hasmuscSnap" value={hasmusc} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Arthritis</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.arthritisSnap" value={arthritis} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Paralysis</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.paralysisSnap" value={paralysis} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>
              Metal implants or prosthesis
            </div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.implantsSnap" value={metalimplants} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Cancer</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.cancerSnap" value={cancer} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Oxygen</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.oxySnap" value={oxygen} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Pre-op physical</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.physicalSnap" value={preopphysical} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Stress Test</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.stressSnap" value={stressTest} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Psychiatric Assessment</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.psychiatricAssessSnap" value={psychiatricAssess} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Cardiac Echo</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.echoSnap" value={echo} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Steroids</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.steroidsSnap" value={steroids} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Stroke or TIA</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.strokeSnap" value={stroke} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>CHF</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.chfSnap" value={chf} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div style={{ borderRight: '1px solid black', borderTop: '1px solid black', paddingLeft:'3px' }}>Problems with airway</div>
            <div style={{ borderTop: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.airwaySnap" value={problemAirway} />
            </div>
          </div>
          <div style={styles.twoColumnsNotEqual}>
            <div
              style={{ borderRight: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black' }}
            >
              Seizures
            </div>
            <div style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
              <MedicalPassportField label="" name="page9.seizuresSnap" value={seizures} />
            </div>
          </div>
        </div>
      </div>
      
      <Footer pageNumber={9} pageCount={12} />
    </div>
  );
};

export default Page9;
