import { Box } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import clsx from 'clsx';
import { format } from 'date-fns';
import addMinutes from 'date-fns/add_minutes';
import isAfter from 'date-fns/is_after';
import get from 'lodash/get';
import React, { useRef, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import localHospital from '../../../../../assets/images/icons/local_hospital.png';
import isMiddleEast from '../../../../../util/isMiddleEast';
import { getRoomTime } from '../../../../../util/procedureEvents';
import { ROOM_TYPES } from '../../../../entities/room/enums';

import { Icon } from '../../SchedulePage';
import { getLogEntries, isBlockNerveFn, isHelpFn, isPreOpAsDPUOptimized } from '../../tablet/utils';
import { Anesthesiologist, Personnel } from '../anesthesiologist/Personnel';
import HighlightedText from '../HighlightedText';
import Patient from '../Patient';
import {
  formatDuration,
  fullNameToDrLastName,
  fullNameToDrLastNameMiddleEast,
  fullNameToLastNameRest,
  getDelayInMinutes,
  getOrLateEntryInMinutes,
  isCompleted,
  isDelayed,
  isDimmed,
  isNoShow,
  NormalizedStatus,
  normalizeStatus,
} from '../shared/procedureUtils';
import MultipleSelectionCheckbox from '../staff/MultipleSelectionCheckbox';
import { StaffShiftProcedureContext } from '../staff/StaffShiftContext';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import StaffShifts from '../staff/StaffShifts';
import { StaffSlotProcedureContext } from '../staff/StaffSlotContext';
import { getInOrStatusLabel } from '../statusUtils';
import Time from '../Time';
import { getColor } from '../timeline/Procedure';
import { VendorProcedureProvider } from '../vendor/context/VendorProcedureContext';
import ScheduleUserButtons from '../vendor/ScheduleUserButtons';
import VendorMenu from '../vendor/VendorMenu';
import Vendors from '../vendor/Vendors';
import Appendix from './Appendix';
import ProcedureColorSelector from './ProcedureColorSelector';
import useStyles from './procedureStyles';
import { unparsedAllergiesSummary } from '../../../../../allergies/AllergiesView';
import { PrimarySpecialty } from '../../../../../types/StaffMember';

const Body = styled.div``;

const Description = styled.div`
  font-family: 'Roboto', 'Roboto Condensed', sans-serif;
  margin: 0.66666em 0.25em;
  display: flex;
  line-height: 1.16;
`;

export const RegularText = styled.span`
  opacity: ${props => (props.highContrast || props.warning || props.panic ? 1 : '.7')};
  color: ${props => (props.panic ? '#FF5ABB' : props.warning ? '#F55323' : props.theme.palette.text.primary)};
  line-height: 1.2;
`;

export const ImageIcon = styled.img`
  width: 1em;
  height: 1em;
  margin-right: 0.333em;
`;

const emphasize = keyframes`
  0% {
    border-color: rgba(255, 0, 0, 0);
    box-shadow: 0 0 4px rgba(255, 0, 0, 0);
  }
  100% {
    border-color: rgba(255, 0, 0, 1);
    box-shadow: 0 0 30px rgba(255, 0, 0, 1);
  }
`;

const HelpContainer = styled.div`
  border: 3px solid transparent;
  ${props =>
    props.isHelp &&
    !props.disabled &&
    css`
      animation: 1.2s ${emphasize} ease-in alternate infinite;
    `}
`;

const Procedure = ({
  hospitalId,
  date,
  procedure,
  isUnassigned,
  isPatientIconVisible,
  onClick,
  highContrast,
  isKiosk,
  editableStaff,
  editableAnesthesiologist,
  showBedNumber,
  condensView,
  vendorColor,
}) => {
  const canAddStaff = !isKiosk && editableStaff;
  const physicianId = get(procedure, 'physician.id');
  const startTime = get(procedure, 'startTime');
  const duration = get(procedure, 'duration');
  const endTime = addMinutes(startTime, duration);
  const normalizedStatus = normalizeStatus(
    procedure?.patient?.status,
    procedure?.patient?.room,
    isMiddleEast(hospitalId)
  );
  const status = !!procedure?.patient?.id ? normalizedStatus : 'PreAdm';
  const admittedAt = get(procedure, 'patient.events.admittedAt');
  const preOpAt = get(procedure, 'patient.events.preOpAt');
  const orAt = get(procedure, 'patient.events.orAt');
  const recoveryAt = get(procedure, 'patient.events.recoveryAt');
  const isCanceled = get(procedure, 'isCanceled');

  const physicianName = isMiddleEast(hospitalId)
    ? fullNameToDrLastNameMiddleEast(procedure?.physician?.name)
    : fullNameToDrLastName(procedure?.physician?.name);
  const anesthesiologistName = isMiddleEast(hospitalId)
    ? fullNameToDrLastName(procedure?.anesthesiologist?.name)
    : fullNameToLastNameRest(procedure?.anesthesiologist?.name);
  const anesthesiaType = procedure?.anesthesiaType;

  const isHoldProcedure = get(procedure, 'patient.events.holdProcedureAt');
  const isBlockNerve = isBlockNerveFn(getLogEntries(get(procedure, 'patient')));
  const isHelp = isHelpFn(getLogEntries(get(procedure, 'patient')));
  const readyForSurgeon = get(procedure, 'patient.events.readyForSurgeonAt');
  const readyForOr = get(procedure, 'patient.events.readyForOrAt');

  const waitingRoomTime = admittedAt
    ? formatDuration(admittedAt, preOpAt || orAt || recoveryAt || date, !(preOpAt || orAt || recoveryAt))
    : '-';

  const toleranceInMinutes = 10;
  const breaksSchedule =
    status !== 'Completed' &&
    status !== 'Canceled' &&
    status !== NormalizedStatus.In_OR &&
    status !== NormalizedStatus.In_PACU &&
    status !== NormalizedStatus.Discharged &&
    status !== NormalizedStatus.Completed &&
    status !== NormalizedStatus.Canceled &&
    isAfter(date, addMinutes(endTime, toleranceInMinutes));

  const formattedDate = useMemo(() => format(date, 'YYYY-MM-DD'), [date]);

  const orLateInMinutes = getOrLateEntryInMinutes(procedure, isMiddleEast(hospitalId));
  const noShow = isNoShow(procedure);

  const ref = useRef();

  const isWaiting = [ROOM_TYPES.WAITING_ROOM, ROOM_TYPES.PRE_OP].includes(get(procedure, 'patient.room.type'));
  const isDPU = isPreOpAsDPUOptimized(procedure?.patient?.room?.type, procedure?.patient?.events);

  const roomTime = getRoomTime(procedure);
  const procedureColor = procedure?.color || getColor(status, hospitalId, null);

  const classes = useStyles();

  const isDisabled = [NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus);
  const hasError = isUnassigned || breaksSchedule;

  const allergies = unparsedAllergiesSummary(procedure.allergies);

  return (
    <StaffShiftProcedureContext
      hospitalId={hospitalId}
      procedureId={procedure.id}
      date={formattedDate}
      staffShifts={procedure.staffShifts || []}
    >
      <HelpContainer isHelp={isHelp} disabled={isCompleted(procedure) || isCanceled || noShow}>
        <Box
          ref={ref}
          onClick={onClick}
          disabled={isDisabled}
          className={clsx(classes.wrapper, {
            [classes.customBorder]: !!procedureColor,
            [classes.dpuFromOrBorder]: isDPU,
            [classes.disabled]: isCompleted(procedure) || isDisabled || isCanceled || noShow,
            [classes.pulsate]: hasError && !isCompleted(procedure),
            [classes.hasError]: hasError,
          })}
          style={procedureColor && !isHelp ? { borderColor: procedureColor } : undefined}
        >
          <Box className={classes.header}>
            {canAddStaff && (
              <Box className={classes.cheboxContainer}>
                <MultipleSelectionCheckbox procedureId={procedure.id} />
                <ProcedureColorSelector procedureId={procedure?.id} color={procedureColor} />
              </Box>
            )}
            <Time
              canAddStaff={canAddStaff}
              startTime={procedure.startTimeText}
              endTime={procedure.endTimeText}
              duration={duration}
              late={orLateInMinutes && isWaiting}
            />
            <Box className={classes.patientAndPersonnel}>
              <Personnel
                date={date}
                procedureId={procedure.id}
                color={get(procedure, 'physician.color') || get(procedure, 'physicianColor') || '#888888'}
                physicianId={physicianId}
                physicianName={physicianName}
                editableAnesthesiologist={editableAnesthesiologist}
                anesthesiologistName={anesthesiologistName}
                anesthesiaType={anesthesiaType}
                condensView={condensView}
              />
              {condensView && (
                <Anesthesiologist
                  date={date}
                  procedureId={procedure.id}
                  condensView={condensView}
                  editableAnesthesiologist={editableAnesthesiologist}
                  anesthesiologistName={anesthesiologistName}
                  anesthesiaType={anesthesiaType}
                />
              )}
              {!condensView && (
                <Patient
                  id={procedure?.patient?.id}
                  name={procedure?.patient?.name || procedure?.patientName}
                  age={get(procedure, 'patientAge')}
                  sex={get(procedure, 'patientSex')}
                  waitingRoomTime={waitingRoomTime}
                  isPatientIconVisible={isPatientIconVisible}
                  status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
                  inOrStatus={
                    normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                  }
                  roomTime={roomTime}
                  bed={showBedNumber && !!procedure?.patient?.bed ? procedure?.patient?.bed : false}
                  condensView={condensView}
                  hospitalId={hospitalId}
                />
              )}
            </Box>

            {condensView && (
              <Box flex={1} flexBasis="100%" mt="1px" style={{ overflow: 'hidden' }}>
                <Patient
                  id={procedure?.patient?.id}
                  name={procedure?.patient?.name || procedure?.patientName}
                  age={get(procedure, 'patientAge')}
                  sex={get(procedure, 'patientSex')}
                  waitingRoomTime={waitingRoomTime}
                  isPatientIconVisible={isPatientIconVisible}
                  status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
                  inOrStatus={
                    normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                  }
                  roomTime={roomTime}
                  bed={showBedNumber && !!procedure?.patient?.bed ? procedure?.patient?.bed : false}
                  condensView={condensView}
                  hospitalId={hospitalId}
                />
              </Box>
            )}
          </Box>
          <Appendix
            isDPU={isDPU}
            isDimmed={isDimmed(procedure)}
            late={orLateInMinutes}
            isDelayed={isDelayed(procedure, isMiddleEast(hospitalId)) && !noShow}
            delay={getDelayInMinutes(procedure)}
            isWaiting={isWaiting}
            readyForSurgeon={procedure?.patient?.room?.type === ROOM_TYPES.PRE_OP && !!readyForSurgeon}
            readyForOr={procedure?.patient?.room?.type === ROOM_TYPES.PRE_OP && !!readyForOr}
            readyForSurgery={procedure?.patient?.status === NormalizedStatus.Ready}
            isHoldProcedure={procedure?.patient?.room?.type === ROOM_TYPES.PRE_OP && !!isHoldProcedure}
            isBlockNerve={
              procedure?.patient?.room?.type === (ROOM_TYPES.PRE_OP || ROOM_TYPES.POST_OP || ROOM_TYPES.PACU) &&
              !!isBlockNerve
            }
            isHelp={procedure?.patient?.room?.type === ROOM_TYPES.OR && isHelp}
          />
          <Body>
            {(procedure.procedureType || procedure.procedurePriority) && (
              <Description style={{ display: 'flex', alignItems: 'left' }}>
                <Badge
                  color="primary"
                  badgeContent={procedure.procedurePriority}
                  style={{ marginTop: '.6em', marginLeft: '.5em' }}
                />
                <div>
                  <ImageIcon
                    src={localHospital}
                    style={{
                      opacity: 0.2,
                      marginTop: '.125em',
                      marginLeft: procedure.procedurePriority ? '1em' : '0em',
                    }}
                  />
                  <HighlightedText highContrast={highContrast}>
                    {procedure.procedureType + (procedure.procedureMod ? ` (${procedure.procedureMod})` : '')}
                  </HighlightedText>
                </div>
              </Description>
            )}
            {procedure.notes && (
              <Description>
                <Icon style={{ color: '#ABE3FF', opacity: 0.2, marginTop: '.125em' }}>assignment</Icon>{' '}
                <HighlightedText highContrast={highContrast}>{procedure.notes}</HighlightedText>
              </Description>
            )}
            {allergies && (
              <Description>
                <Icon style={{ color: '#ABE3FF', opacity: 0.2, marginTop: '.125em' }}>warning</Icon>
                <HighlightedText highContrast={highContrast}>{allergies}</HighlightedText>
              </Description>
            )}
            <Box px={0.5} pb={0.5}>
              <StaffSlotProcedureContext
                hospitalId={hospitalId}
                procedureId={procedure.id}
                date={formattedDate}
              >
                <StaffShifts
                  staffShifts={procedure.staffShifts || []}
                  procedure={procedure}
                  isKiosk={isKiosk}
                  editableStaff={editableStaff}
                  type={'ProcedureShift'}
                >
                  {canAddStaff && (
                    <StaffShiftMenu procedure={procedure} defaultPrimarySpecialty={PrimarySpecialty.OR} />
                  )}
                </StaffShifts>
              </StaffSlotProcedureContext>
            </Box>
            <Box px={0.5} pb={0.5}>
              <VendorProcedureProvider procedureId={procedure.id} date={formattedDate}>
                <Vendors procedure={procedure} editableStaff={editableStaff} vendorColor={vendorColor}>
                  {canAddStaff && <VendorMenu procedure={procedure} />}
                </Vendors>
              </VendorProcedureProvider>
            </Box>
            <ScheduleUserButtons procedure={procedure} />
          </Body>
        </Box>
      </HelpContainer>
    </StaffShiftProcedureContext>
  );
};

export default Procedure;
