import React from 'react';
import Header from './components/Header';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import PatientInfo from './components/PatientInfo';
import Footer from './components/Footer';
import styles2, { getGridHeaderChildStyles, getGridParentStyles } from './styles';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import MedicationReconciliationField from '../components/MedicationReconciliationField';
import MedicationTextArea from '../components/MedicationTextArea';
import toStringWithoutQuestions from '../../../../../questionnaire/toStringWithoutQuestions';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import toHighlightsString3 from '../../../../../questionnaire/toHighlightsString3';
import { fonts } from 'pdfmake/build/pdfmake';

const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '75% 25%' };
const priorSurguriesTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '33.33% 33.33% 33.33%' };

function yesOrNo(value) {
    if (value === true) {
        return 'yes';
    } else if (value === false) {
        return 'no';
    }
    return '';
}

function yesOrNo2(value, value1) {
    if (value === true) {
        return 'yes';
    } else if (value1 === false) {
        return 'no';
    }
    return '';
}

function yesOrNo3(value) {
    if (value === null || value === '') {
        return '';
    } else if (value.length === 0) {
        return 'no';
    }
    return 'yes';
}

function yesOrNo4(value) {
    if (value === null || value === '') {
        return '';
    } else if (value.none === true) {
        return 'no';
    } else if (value.length === 0) {
        return 'no';
    }
    return 'yes';
}

const Page9 = () => {
    const { questionnaireAnswers } = useValue();
    let patientProblem = get(
        questionnaireAnswers,
        'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
        ''
    )
        ? 'Patient:' +
        get(
            questionnaireAnswers,
            'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
            ''
        )
        : '';

    let familyProblem = get(
        questionnaireAnswers,
        'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
        ''
    )
        ? 'Family:' +
        get(
            questionnaireAnswers,
            'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
            ''
        )
        : '';

    let anesthesiaProblem = patientProblem + '\t' + familyProblem;
    // medication
    const medicationList = get(
        questionnaireAnswers,
        'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
        []
    );
    let nameArr = [medicationList.length];
    let doseArr = [medicationList.length];
    let routeArr = [medicationList.length];
    let freqArr = [medicationList.length];
    let result = [medicationList.length];
    let purposeArr = [medicationList.length];
    let last = [medicationList.length];
    let status = [medicationList.length];

    for (let i = 0; i < medicationList.length; i++) {
        nameArr[i] = get(medicationList[i], 'schema.name.text', '');
        doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
        routeArr[i] = get(medicationList[i], 'schema.route.option', '');
        freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
        result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
        last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

        purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
        status[i] = get(medicationList[i], 'schema.status.option');
    }

    // allergy
    function yesNoAllergies() {
        const allergyQ1 = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[0]', false);
        const allergyQ2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
        const allergyQ3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

        return allergyQ1 || allergyQ2 || allergyQ3;
    }
    const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
    const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
    const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

    const allergyName = [];
    const reaction = [];

    for (let i = 0; i < allergyList.length; i++) {
        allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
        reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
    }

    if (allergyList2) {
        allergyName.push('Latex');
        reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
    }

    if (allergyList3) {
        allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
        reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
    }

    // prior surgery
    const surgeryList = get(
        questionnaireAnswers,
        'sections.priorSurgeries.schema.q1.yes[1].schema.surgery_list.list',
        []
    );
    let surgeryProcedure = [surgeryList.length];
    let surgeryYear = [surgeryList.length];

    for (let i = 0; i < surgeryList.length; i++) {
        surgeryProcedure[i] = get(surgeryList[i], 'schema.procedure.text', '');
        surgeryYear[i] = get(surgeryList[i], 'schema.year.text', '');
    }

    return (
        <div id="page9" className="page" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
            <Header pageNumber={10} pageCount={12} />
            <div style={{ textAlign: 'center' }}>
                <h2>HISTORY & PHYSICAL</h2>
            </div>
            <PatientInfo />
            <br></br>
            <div style={{ display: 'grid', gridTemplateColumns: '26% 74%', fontSize:'12px' }}>
                Patient/Family/Complications:
                <MedicationTextArea name="page9.complications" value={anesthesiaProblem} reducer={toHighlightsString3} fontSize={12}/>
            </div>
            <MedicalPassportField label="Preoperative Diagnosis" name="page9.preopDiagnosis"  fontSize={12}/>
            <br></br>
            <div style={styles.greyBackground}>
                Allergies (Patient Answered{' '}
                <span>
                    <input
                        type="checkbox"
                        name="allergiesYes"
                        label="Yes"
                        style={{ display: 'inline' }}
                        checked={yesNoAllergies()}
                    />
                </span>
                )
            </div>
            <div style={getGridParentStyles(2, true)}>
                <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'center' }}>
                    Name of Allergy
                    {allergyName.length === 0 ? (
                        <div>
                            <MedicationReconciliationField name='page9Allergy' value="NO KNOWN DRUG ALLERGIES"/>
                        </div>
                    ) : (
                        [...Array(allergyName.length)].map((_, i) => (
                            <div key={i}>
                                <MedicationReconciliationField name={`medication.r${i}.c0`} value={allergyName[i]}/>
                            </div>
                        ))
                    )}
                </div>
                <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'center' }}>
                    Reaction of sensitivity
                    {[...Array(reaction.length)].map((_, i) => (
                        <>
                            <div>
                                <MedicationReconciliationField name={`medication.r${i}.c0`} value={reaction[i]}/>
                            </div>
                        </>
                    ))}
                </div>
            </div>
            <br></br>
            <div style={styles.greyBackground}>
                Medications (Patient Answered{' '}
                <span>
                    <MedicalPassportCheckbox
                        name="medYes"
                        label="Yes"
                        style={{ display: 'inline' }}
                        path={['medications', 'medicationList']}
                    />
                </span>
                )
            </div>
            <div style={medicationTable}>
                <div>Medication, dosage, route, frequency</div>
                <div>Reason</div>

                {[...Array(medicationList.length)].map((_, i) => (
                    <>
                        <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0 }}>
                            <MedicationTextArea name={`medication.r${i}.c0`} value={result[i]} style={{fontSize:'11px'}}/>
                        </div>
                        <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
                            <MedicationTextArea name={`medication.r${i}.c2`} value={purposeArr[i]} style={{fontSize:'11px'}}/>
                        </div>
                    </>
                ))}
            </div>

            <div style={styles.greyBackground}>
                Surgeries (Patient Answered{' '}
                <span>
                    <MedicalPassportCheckbox
                        name="medYes"
                        label="Yes"
                        style={{ display: 'inline' }}
                        path={['priorSurgeries', 'q1']}
                    />
                </span>
                )
            </div>
            <div style={priorSurguriesTable}>
                <div>Procedure</div>
                <div>Year</div>
                <div>Anesthesia Type</div>

                {[...Array(surgeryList.length)].map((_, i) => (
                    <>
                        <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
                            <MedicationTextArea name={`surgeries.r${i}.c0`} value={surgeryProcedure[i]} style={{fontSize:'11px'}}/>
                        </div>
                        <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
                            <MedicationTextArea name={`surgeries.r${i}.c1`} value={surgeryYear[i]} style={{fontSize:'11px'}}/>
                        </div>
                        <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0 }}>
                            <MedicationTextArea name={`surgeries.r${i}.c2`} style={{fontSize:'11px'}}/>
                        </div>
                    </>
                ))}
            </div>
            <br></br>
            <Footer pageNumber={10} pageCount={12} />
        </div>
    );
};

export default Page9;
