import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { unpackSessionObject } from '../../components/pages/unpackSessionObject';
import { withSession } from '../../state/Session';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  backLink: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',

      '& > *': {
        marginLeft: 0,
      },

      '& > a': {
        marginRight: '0.5rem',
      },
    },
  },
}));

const TitleAction = ({ Back, Title, children, style = {}, actionStyle = {} }) => {
  const classes = useStyles();

  return (
    <Box style={style} className={classes.root}>
      <Box className={classes.title}>
        {Back ? <Back className={classes.backLink} /> : null}
        {Title ? <Title /> : null}
      </Box>
      {children && <Box className={classes.actions}>{children}</Box>}
    </Box>
  );
};

export default withSession(unpackSessionObject)(TitleAction);
