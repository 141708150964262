import React from 'react';
import { Paragraph, TruncatedText } from '../../../se/components/typography';
import NameTagList from '../../core/tag/NameTagList';
import AddressLink from '../../../se/components/html/AddressLink';
import PhoneLink from '../../../se/components/html/PhoneLink';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from '@material-ui/core';
import CovidScreeningToggle from '../covidScreening/CovidScreeningToggle';

const Empty = () => <Typography color="textSecondary">–</Typography>;

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  Component: props => props.data,
};

export const baseColumns = [
  nameColumn,
  {
    title: 'Address',
    key: 'address',
    lens: item => item.address,
    Component: props => (props.data ? <AddressLink>{props.data}</AddressLink> : <Empty />),
  },
  {
    title: 'Contact',
    key: 'contact',
    lens: item => item.contact,
    Component: props => (props.data ? <PhoneLink>{props.data}</PhoneLink> : <Empty />),
  },
];

export const LinkColumn = ({ data }) => {
  const { link, enabled } = defaultTo(data, {});
  return link ? (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link href={link} variant="body1" style={{ overflow: 'hidden', wordWrap: 'anywhere' }}>
        {link}
      </Link>
      <Box color={enabled ? 'success.main' : 'error.main'}>
        <Typography variant="body2">{enabled ? 'Enabled' : 'Disabled'}</Typography>
      </Box>
    </div>
  ) : (
    <Empty />
  );
};

export const allColumns = [
  ...baseColumns,
  {
    title: 'Minimum Active Bracelets',
    lens: item => item.minBracelets,
    Component: props => (
      <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.data ? props.data : <Empty />}</Paragraph>
    ),
  },
  {
    title: 'Session Expiration Timeout',
    lens: item => item.sessionTimeout,
    Component: props => (
      <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.data ? props.data : <Empty />}</Paragraph>
    ),
  },
  {
    title: 'Timezone',
    span: 1,
    lens: item => get(item, 'timezone.name', ''),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Patient Google Review Integration',
    span: 1,
    lens: item => get(item, 'reviewIntegrations.google', {}),
    Component: LinkColumn,
  },
  {
    title: 'Patient Yelp! Review Integration',
    span: 2,
    lens: item => get(item, 'reviewIntegrations.yelp', {}),
    Component: LinkColumn,
  },
  {
    title: 'Caretaker Google Review Integration',
    lens: item => get(item, 'caretakerReviewIntegrations.google', {}),
    span: 1,
    Component: LinkColumn,
  },
  {
    title: 'Caretaker Yelp! Review Integration',
    lens: item => get(item, 'caretakerReviewIntegrations.yelp', {}),
    span: 2,
    Component: LinkColumn,
  },
  {
    title: 'Ospitek Orders Integration',
    lens: item => get(item, 'ospitekOrdersIntegrations.externalLink', {}),
    span: 3,
    Component: LinkColumn,
  },
  {
    title: 'Group Administrators',
    span: 3,
    lens: item => item.groupAdmins,
    Component: props => <NameTagList tags={props.data} linkForElement={id => `/users/${id}`} />,
  },
  {
    title: 'Monitoring Engineers',
    span: 3,
    lens: item => item.monitors,
    Component: props => <NameTagList tags={props.data} linkForElement={id => `/users/${id}`} />,
  },
  {
    title: 'Notes',
    lens: item => item.notes,
    span: 3,
    Component: props => (
      <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.data ? props.data : <Empty />}</Paragraph>
    ),
  },
  {
    title: 'Modules',
    lens: item => item,
    span: 1,
    Component: CovidScreeningToggle,
  },
];
